<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button
            type="primary"
            @click="showAddAdminDialog"
          >+ 新增管理员</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <!-- 输入框，尾部带按钮 -->
          <el-input
            placeholder="请输入管理员名称"
            v-model="queryInfo.userName"
            clearable
            @clear="getAdminList"
            @change="searchAdmin"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchAdmin"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table
        :data="adminList"
        stripe
        border
      >
        <!-- 第一列id -->
        <el-table-column
          label="id"
          width="100"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 第二列名称 -->
        <el-table-column
          label="用户账号"
          aligin="center"
          prop="userName"
        >
        </el-table-column>
        <!-- 第三列手机号 -->
        <el-table-column
          label="手机号"
          aligin="center"
          prop="mobile"
        >
        </el-table-column>
        <!-- 第四列邮箱 -->
        <!-- <el-table-column label="邮箱" width="150" aligin="center" prop="email">
        </el-table-column> -->
        <!-- 第四列角色 -->
        <el-table-column
          label="角色"
          aligin="center"
        >
          <template slot-scope="scope">
            <el-tag
              v-for="item in scope.row.roleVoList"
              :key="item.id"
            >
              {{ item.roleName }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- 第五列创建时间 -->
        <el-table-column
          label="最近登录IP"
          aligin="center"
          prop="latestIp"
        >
        </el-table-column>
        <el-table-column
          label="登录时间"
          aligin="center"
          prop="latestAccessTime"
        >
        </el-table-column>
        <!-- 第六列状态 -->
        <el-table-column
          label="管理市场"
          aligin="center"
        >
          <template slot-scope="scope">
            <el-tag
              v-for="item in scope.row.marketList"
              :key="item.id"
            >{{
              item.marketName
            }}</el-tag>
          </template>
        </el-table-column>
        <!-- 第六列状态 -->
        <!-- <el-table-column label="状态" width="200" aligin="center" prop="status">
        </el-table-column> -->
        <!-- 第七列操作 -->
        <el-table-column
          label="操作"
          width="200"
          aligin="center"
        >
          <template slot-scope="scope">
            <!-- 编辑按钮 -->
            <el-button
              size="mini"
              type="primary"
              @click="showEditAdminDialog(scope.row)"
            >修改</el-button>
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeAdminById(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog
      @close="clearDialog"
      :title="title"
      :visible.sync="addAdminDialogVisable"
      width="50%"
    >
      <el-form
        ref="addAdminRef"
        :rules="addAdminRules"
        :model="adminUserForm"
        label-width="80px"
      >
        <el-form-item
          label="用户名称"
          prop="userName"
        >
          <el-input
            v-model="adminUserForm.userName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="用户手机"
          prop="mobile"
        >
          <el-input
            v-model="adminUserForm.mobile"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="用户密码"
          prop="password"
          v-if="isShow"
        >
          <el-input
            v-model="adminUserForm.password"
            clearable
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="用户角色"
          prop="roleId"
        >
          <el-select
            v-model="adminUserForm.roleId"
            multiple
            placeholder="请选择角色"
            @change="select"
          >
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="所属市场"
          prop="marketId"
        >
          <el-select
            v-model="adminUserForm.marketId"
            placeholder="请选择市场"
            multiple=""
            disabled
          >
            <el-option
              v-for="item in marketList"
              :key="item.id"
              :label="item.marketName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="选择角色" prop="marketId">
          <el-select v-model="adminUserForm.marketId" placeholder="请选择角色">
            <el-option
              v-for="item in marketList"
              :key="item.id"
              :label="item.marketName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="邮箱" prop="email">
          <el-input v-model="adminUserForm.email" clearable></el-input>
        </el-form-item> -->
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addAdminDialogVisable = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 角色对话框 -->
    <el-dialog
      title="当前角色"
      :visible.sync="roleDialogVisible"
      width="500px"
    >
      <!-- 卡片视图区域 -->
      <!-- 第一行添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button
            type="primary"
            @click="showAddAdminRoleDialog"
          >添加角色</el-button>
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table
        :data="userRoleList"
        stripe
        border
      >
        <!-- 第一列id -->
        <el-table-column
          label="id"
          width="60"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 第二列名称 -->
        <el-table-column
          label="角色名称"
          width="200"
          aligin="center"
          prop="roleName"
        >
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 添加角色对话框 -->
    <el-dialog
      title="添加角色"
      :visible.sync="addAdminRoleVisiable"
      width="30%"
    >
      <span style="margin-right:10px">角色列表</span>
      <el-select
        v-model="roleId"
        multiple
        placeholder="请选择角色"
      >
        <el-option
          v-for="item in roleList"
          :key="item.id"
          :label="item.roleName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addAdminRoleVisiable = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitAddAdminRole"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    // 手机号校验规则
    var checkMobile = (rule, value, callback) => {
      // 手机正则表达式
      const regMobile = /^(0|86|17951)?(1[0-9])[0-9][0-9]{8}$/
      if (regMobile.test(value)) {
        // 合法的手机号
        return callback()
      }
      callback(new Error('请输入合法的手机号'))
    }
    return {
      // 查询管理员入参
      queryInfo: {
        limit: 15,
        pageNo: 1,
        userName: '',
        marketId: ''
      },
      // 管理员列表
      adminList: [],
      // 总数
      total: 0,
      // 管理员添加对话框
      addAdminDialogVisable: false,
      title: '',
      submitType: '',
      // 新增入参
      adminUserForm: {
        email: '',
        marketId: [],
        mobile: '',
        password: '',
        userName: '',
        roleId: []
      },
      // 角色查询入参
      RolequeryInfo: {
        limit: 100,
        pageNo: 1,
        marketId: ''
      },
      // 角色list
      roleList: [],
      // 添加对话框规则
      addAdminRules: {
        userName: [
          { required: true, message: '请填写用户名称', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请填写手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        password: [{ required: true, message: '请填写密码', trigger: 'blur' }],
        marketId: [
          { required: true, message: '请选择市场名称', trigger: 'blur' }
        ],
        roleId: [{ required: true, message: '请选择角色', trigger: 'blur' }]
      },
      // 市场列表
      marketList: [],
      // 打开修改框，赋值userId
      userId: '',
      // 控制密码，添加时显示，修改时不展示
      isShow: true,
      // 角色对话框
      roleDialogVisible: false,
      // 当前用户下的角色
      userRoleList: [],
      // 添加角色对话框
      addAdminRoleVisiable: false,
      // 提交添加角色的入参
      roleId: [],
      // 添加角色时所需要的用户id
      addRoleId: ''
    }
  },
  created () {
    // 获取管理员列表
    this.getAdminList()
  },
  methods: {
    searchAdmin () {
      this.queryInfo.pageNo = 1
      this.getAdminList()
    },
    // 定义管理员列表请求数据
    async getAdminList () {
      // 取marketId
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get('adminUser/adminUserList', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查询管理员失败')
      }
      this.adminList = res.data.data
      this.total = res.data.total
    },
    // 打开增加管理员对话框
    showAddAdminDialog () {
      // 调用角色数据请求
      this.getRoleList()
      // 调用市场列表数据请求
      this.getMarketList()
      this.title = '添加管理员'
      this.submitType = 'add'
      this.isShow = true
      this.adminUserForm.marketId = [
        parseInt(window.sessionStorage.getItem('currentMarketId'))
      ]

      this.addAdminDialogVisable = true
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getAdminList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getAdminList()
    },
    // 打开修改对话框
    showEditAdminDialog (row) {
      // 调用角色数据请求
      this.getRoleList()
      // 调用市场列表数据请求
      this.getMarketList()
      this.title = '修改管理员'
      this.submitType = 'edit'
      this.isShow = false
      // 赋值
      this.adminUserForm = row
      this.userId = row.id
      this.adminUserForm.marketId = row.marketList.map(item => item.id)
      // 给用户角色id赋值
      this.adminUserForm.roleId = row.roleVoList.map(function (item, index) {
        return parseInt(item.id)
      })
      console.log(this.adminUserForm.roleId)
      this.addAdminDialogVisable = true
    },
    // 删除管理员
    async removeAdminById (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该管理员，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`adminUser/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除管理员失败')
      }
      this.$message.success('删除管理员成功')
      this.getAdminList()
    },
    // 关闭对话框，清空参数
    clearDialog () {
      this.adminUserForm = {}
      this.$refs.addAdminRef.resetFields()
      this.getAdminList()
    },
    // 角色列表数据请求
    async getRoleList () {
      // 获取marketId
      this.RolequeryInfo.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      const { data: res } = await this.$http.get('adminRole/adminRoleList', {
        params: this.RolequeryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('获取角色列表失败')
      }
      // 赋值
      this.roleList = res.data.data
    },
    // 请求市场
    async getMarketList () {
      const { data: res } = await this.$http.get(
        'agriculturalMarket/marketList?limit=100&pageNo=1'
      )
      if (res.code !== 0) {
        return this.$message.error('请求市场列表失败')
      }
      this.marketList = res.data.data
    },
    // 提交新增和修改请求
    async submit () {
      this.$refs.addAdminRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          const { data: res } = await this.$http.post(
            'adminUser/',
            this.adminUserForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加管理员成功')
          this.getAdminList()
          this.addAdminDialogVisable = false
        } else {
          // 赋值成功后提交
          const { data: res } = await this.$http.put(
            `adminUser/${this.userId}`,
            this.adminUserForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('修改管理员成功')
          this.getAdminList()
          this.addAdminDialogVisable = false
        }
      })
    },
    // 打开角色对话框
    async openRoleDialog (id) {
      // 用户id赋值
      this.addRoleId = id
      // 查询最新的角色
      this.getUserRole()
      // 打开角色对话框
      this.roleDialogVisible = true
    },
    // 查看用户所属角色
    async getUserRole () {
      // 查看当前账号所属角色
      const { data: res } = await this.$http.get(
        `adminUser/${this.addRoleId}/role`
      )
      if (res.code !== 0) {
        return this.$message.error('查询角色失败')
      }
      this.userRoleList = res.data
    },
    // 点击添加角色按钮
    showAddAdminRoleDialog () {
      // 调用查看角色列表
      this.getRoleList()
      this.addAdminRoleVisiable = true
    },
    // 提交添加角色请求
    async submitAddAdminRole () {
      const { data: res } = await this.$http.post(
        `adminUser/${this.addRoleId}/role`,
        this.roleId
      )
      if (res.code !== 0) {
        return this.$message.error('添加角色失败')
      }
      this.$message.success('添加角色成功')
      // 查询最新的角色
      this.getUserRole()
      // 关闭对话框
      this.addAdminRoleVisiable = false
    },
    select () {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.el-col {
  margin-right: 20px;
}
.el-tag {
  margin: 7px;
}
.el-row {
  margin-bottom: 20px;
}
</style>
